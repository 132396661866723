
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import store from "@/store";
import router from "@/router";
import Divisao from "@/interfaces/Divisao";
import DivisaoService from "@/services/auth/DivisaoService";
import User from "@/interfaces/User";
import ContaService from "@/services/conta/ContaService";

interface Form {
  divisao_id: string;
}

const baseForm: Form = {
  divisao_id: "",
};

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const form = ref<Form>(baseForm);
    const divisoes = ref<Divisao[]>([]);
    const loading = ref<boolean>(true);
    const user = ref<User>();

    const submit = (): void => {
      if (form.value.divisao_id) {
        loading.value = true;
        let id = form.value.divisao_id;
        store
          .dispatch("divisao", { id: id })
          .then(() => {
            router.push("/");
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao escolher divisão",
            });
          })
          .finally(() => (loading.value = false));
      }
    };
    const loginGeneral = (): void => {
      store
        .dispatch("remove_divisao")
        .then(() => router.push("/"))
        .catch(() => {
          showNotify({
            type: "negative",
            message: "Erro ao escolher divisão",
          });
        })
        .finally(() => (loading.value = false));
    };

    const getUser = (): void => {
      loading.value = true;
      ContaService.get()
        .then((res) => {
          user.value = res.data;
          if (user.value?.perfil.nome == "administrador") {
            getDivisoes();
          } else {
            getMinhasDivisoes();
          }
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar dados",
          });
        });
    };

    const getDivisoes = (): void => {
      loading.value = true;
      DivisaoService.list()
        .then((res) => {
          divisoes.value = res.data;
          form.value.divisao_id = res.data[res.data.length - 1].id;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getMinhasDivisoes = (): void => {
      loading.value = true;
      DivisaoService.minhasDivisoes()
        .then((res) => {
          divisoes.value = res.data;
          if (divisoes.value.length == 1) {
            redirectDivisaoUnica();
          } else {
            loading.value = false;
          }
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        });
    };

    const redirectDivisaoUnica = (): void => {
      form.value.divisao_id = divisoes.value[0].id;
      submit();
    };

    const logout = (): void => {
      loading.value = true;
      store
        .dispatch("logout")
        .then(() => router.push("/login"))
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getUser();
      form.value.divisao_id = "";
      if (store.getters.divisaoId) {
        router.push("/");
      }
    });

    return {
      user,
      divisoes,
      requiredField,
      form,
      loading,
      showNotify,
      submit,
      logout,
      getDivisoes,
      getMinhasDivisoes,
      loginGeneral,
    };
  },
});
